.request-email-button {
  line-height: 2rem;
}

.email-input {
  border-width: 0px;
  border-color: #ffffff00;
}

.email-input:focus {
  box-shadow: none;
}
